<template>
  <div class="lux-config">
    <div class="lux-tabs">
      <template v-for="(item, idx) in tabs">
        <div :key="idx" v-if="canOp(item.opCode)" :class="['lux-tab', curTab == item.code ? 'tab-active' : '']"
          @click="switchTab(item.code)">{{showLang(item.lang)}}</div>
      </template>
    </div>
    <div class="lux-tabcontent">
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == curTab && canOp(item.opCode)" :key="idx" v-bind:is="item.com" :args="item.args">
        </component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import StationIndex from './station/Index'
// import ParamIndex from './lux/Index'
// import RuleIndex from './luxrule/Index'
import RuleIndex from './rule/Index'
import StationIndex from './device/Index'
// import RuleIndex from './luxrule/ModalRuleEdit'
import OutputGroupIndex from './outputgroup/Index'
import LightGroupIndex from './lightgroup/Index'
import UnionIndex from './union/LuxIndex'
export default {
  name: 'LuxConfigIndex',
  components: {
  },
  data() {
    return {
      curTab: 's2',
      tabs: [
        { code: 's3', name: '控制分组管理', lang: 'lux.control.group', com: OutputGroupIndex, args: {}, opCode: ["lgv1", "lga1", "lge1", "lgd1"] },
        { code: 's4', name: '单灯分组管理', lang: 'lux.light.group', com: LightGroupIndex, args: {}, opCode: ["lgv1", "lga1", "lge1", "lgd1"] },
        { code: 's2', name: '光控规则管理', lang: 'lux.rule', com: RuleIndex, args: {}, opCode: ["lrv", "lra", "lre", "lrd"] },
        { code: 's8', name: '光控站点管理', lang: 'lux.Site', com: StationIndex, args: { start: 3, end: 24 }, opCode: ["lsv", "lsa", "lse", "lsd"] },
        { code: 's9', name: '联动管理', lang: 'lux.union', com: UnionIndex, args: { start: 24, end: 24000 }, opCode: ["ldv", "lda", "lde", "ldd"] },
        // { code: 's1', name: '光控设备管理', lang: 'lux.device', com: ParamIndex, args: {}, opCode: ["lpv1", "lpa1", "lpe1", "lpd1"] },
      ],
    }
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  computed: {
    ...mapState('auth', ['user', 'ops']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['selectedNode']),
    ...mapState('common', ['weeks', 'productCodes']),
  },
  methods: {
    canOp(opCode) {
      if (this.user && this.user.isSuper) return true;
      if (opCode.length == 0) return true;
      for (let index = 0; index < opCode.length; index++) {
        if (this.ops && this.ops.indexOf(opCode[index]) != -1) {
          return true;
        }
      }
      return false;
    },
    switchTab: function (type) {
      this.curTab = type;
    },
    switchMenu: function (menu) {
      this.$store.commit('auth/setStationMenu', menu);
    },
    showDeviceDetail: function (dev) {
      this.$refs.tree.selectNodeByData(dev);
    },
  }
}
</script>
<style scoped>
.lux-config {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;
  background: #fff;
  padding: 20px;
}
.lux-item {
  width: 200px;
  height: 200px;
  flex: none;
  margin: 10px;
  border: solid 1px lightgray;
  text-align: center;
  padding: 40px;
}
.lux-tabs {
  height: 46px;
  flex: none;
  display: flex;
  margin-bottom: 20px;
}

.lux-tab {
  height: 46px;
  background: #e8f0f7;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000;
  text-align: center;
  line-height: 46px;
  cursor: pointer;
  margin-left: 1px;
  padding: 0 15px;
}
.lux-tabs .lux-tab:first-child {
  border-radius: 8px 0px 0px 8px;
}
.lux-tabs .lux-tab:last-child {
  border-radius: 0px 8px 8px 0px;
}
.tab-active {
  background: #007eff;
  color: #ffffff;
}
.lux-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px blue; */
  overflow: hidden;
}
</style>
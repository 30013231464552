<template>
  <Modal v-model="showModal" :mask-closable="false" width="1300px">
    <div slot="header">{{showLang('lux.edit.lux.info')}}</div>
    <div class="config-lux-container">
      <div class="lux-data-area" ref="table">
        <table class="table-style" style="width: 100%" border="1" cellspacing="0">
          <tr>
            <td class="tr p5 nowrap">{{showLang('lux.Rule.Name')}}</td>
            <td style="padding: 2px;">
              <Input type="text" v-model="editItem.name">
              <!-- <span slot="prepend">属性名称</span> -->
              <!-- <span slot="append">秒</span> -->
              </Input>
            </td>
            <td class="remark">
            </td>
          </tr>
          <tr>
            <td class="tr p5 nowrap" style="width: 100px;">{{showLang('lux.data.acquisition.period')}}</td>
            <td style="width: 1010px;">
              <table style="width: 100%">
                <tr v-for="(int, idx) in editItem.intervals" :key="idx">
                  <td>
                    <Input type="number" v-model="int.val" number>
                      <span slot="prepend">{{showLang('lux.less.equal')}}</span>
                      <span slot="append">lux</span>
                    </Input>
                  </td>
                  <td>
                    <Input type="number" v-model="int.interval" number>
                      <span slot="prepend">{{showLang('lux.acquisition.period')}}</span>
                      <span slot="append">秒</span>
                    </Input>
                  </td>
                  <td style="display: flex;height: 38px;align-items: center;padding-left: 5px;">
                    <Button type="info" @click="add(idx+1)" style="margin-right:5px">+</Button>
                    <Button type="error" @click="del(idx)">-</Button>
                  </td>
                </tr>
                <tr>
                  <td class="nowrap tr p5">
                    {{showLang('lux.default.acquisition.period')}}
                  </td>
                  <td>
                    <Input type="number" v-model="editItem.interval" number>
                    <span slot="prepend">{{showLang('lux.acquisition.period')}}</span>
                    <span slot="append">秒</span>
                    </Input>
                  </td>
                  <td style="padding-left: 5px;">
                    <Button type="info" @click="add(0)">+</Button>
                  </td>
                </tr>
              </table>
            </td>
            <td class="remark">
              {{showLang('lux.edit.lux.tell1')}}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ModalRuleEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      tabHeight: 300,
      currentStationId: 0,
      showEditModal: false,
      loading: false,
      isAdd: false,
      editItem: {
        id: 0,
        name: '',
        interval: 60,
        intervals: [],
      },
    }
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    value(newVal) {
      this.showModal = newVal;
      if (this.value) {
        // console.log('open modal', this.item)
        if (this.item.isAdd) {
          this.editItem.id = 0;
          this.editItem.name = '';
          this.editItem.interval = 60;
          this.editItem.intervals = [{ val: 100, interval: 30 }];
        } else {
          this.editItem.id = this.item.data.id;
          this.editItem.name = this.item.data.name;
          this.editItem.interval = this.item.data.interval;
          this.editItem.intervals = this.item.data.intervals;
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function () {
    // this.getLuxRule();
  },
  methods: {
    addBright: function () {
      this.editItem.brights.push({ start: 0, val: 100, enable: true, bright: 100, color: 100 });
    },
    insertBright: function (idx) {
      // console.log('add lux rule idx', idx)
      if (idx <= 0) {
        this.$set(this.editItem.brights, this.editItem.brights.length, { start: 0, val: 100, enable: true, bright: 100, color: 100 });
      } else {
        this.editItem.brights.splice(idx - 1, 0, { start: 0, val: 100, enable: true, bright: 100, color: 100 });
      }
    },
    delBright: function (idx) {
      if (idx < 0) return;
      this.editItem.brights.splice(idx, 1);
    },
    del: function (idx) {
      if (idx < 0) return;
      this.editItem.intervals.splice(idx, 1);
    },
    add: function (idx) {
      if (idx <= 0) {
        this.$set(this.editItem.intervals, this.editItem.intervals.length, { val: 100, interval: 30 });
      } else {
        this.editItem.intervals.splice(idx - 1, 0, { val: 101, interval: 60 });
      }
    },
    ok: function () {
      // console.log('save lux rule', this.editItem)
      this.$axios.post(`device/lux/SaveRule`, this.editItem).then(res => {
        if (res.code === 0) {
          this.$Message.info(this.showLang('com.tips.save'))
          this.showModal = false;
          this.$emit('saved');
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight;
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.remark {
}
.table-style,
.table-style td {
  border: solid 1px rgba(7, 156, 156, 0.356);
}
/* .table-style td{
  padding: 5px 10px;
} */
.config-lux-container {
  /* padding: 5px;
  padding-right: 50px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  /* border: solid 1px red; */
}
.lux-search-area {
  height: 42px;
  flex: none;
  border: solid 1px #dcdee2;
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 3px 10px;
  display: flex;
  align-items: center;
}
.lux-data-area {
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  border: solid 1px #dcdee2;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: auto;
}
.lux-data-item {
  width: 100%;
  height: 200px;
  flex: none;
  /* border: solid 1px red; */
  border: solid 1px #cad1d9;
  margin-bottom: 30px;
  border-radius: 6px;
}
.lux-item-header {
  display: flex;
  height: 48px;
  align-items: center;
  background: linear-gradient(270deg, #3682ff, #4c9eff);
  border-radius: 6px 6px 0px 0px;
}

.header-title {
  line-height: 48px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  flex: none;
  /* width: 74px; */
  display: flex;
  align-items: center;
}
.header-title div {
  width: 6px;
  height: 6px;
  background: #ffffff;
  margin: 0 10px 0 20px;
}
.header-extra {
  cursor: pointer;
  width: 74px;
  height: 22px;
  border: 1px solid #1b5fa8;
  border-radius: 4px;
  margin-right: 20px;
  flex: none;
  text-align: center;
  background-color: #ffffff;
  color: #3880fc;
}
.header-content {
  flex: auto;
}
.edit-content {
  padding: 20px;
}
</style>
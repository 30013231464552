<template>
  <Modal v-model="showModal" :mask-closable="false" width="720px">
    <div slot="header">{{showLang('lux.con.group.lamp')}}</div>
    <div class="form-area">
      <div class="channel-area">
        <!-- <div class="channel-tree">
          <CommonTree />
        </div> -->
        <div class="channel-chks">
          <!-- <div class="chks-header">
            {{showLang('com.export.cn.plan.relate')}}：
            <Select v-model="filter.timeId" style="width: 350px; margin-right: 10px;">
              <Option :value="0">{{showLang('com.ins.timeTable.all')}}</Option>
              <template v-for="(item, idx) in timeTables">
                <Option :value="item.id" :key="idx">[{{item.mode}}][{{item.type}}]{{item.name}}</Option>
              </template>
            </Select>
            <div class="show-area">
              {{showLang('lux.selected.single')}}
            </div>
          </div> -->
          <div class="chks-list">
            <!-- <u-table ref="table" style="width: 520px; flex:none;" :data="devices" row-key="id" use-virtual border stripe :height="485" :big-data-checkbox="true" default-expand-all :empty-text="showLang('com.data.no')">
              <u-table-column prop="id" type="selection"></u-table-column>
              <u-table-column prop="id" type="index"></u-table-column>
              <u-table-column prop="name" :label="showLang('lux.lamp.name')" sortable width="110">
                <template slot-scope="params">
                  {{params.row.lightName}}
                </template>
              </u-table-column>
              <u-table-column prop="location" :label="showLang('com.site.name')" sortable width="120">
                <template slot-scope="params">
                  {{params.row.stationName}}
                </template>
              </u-table-column>
              <u-table-column prop="timeId" :label="showLang('com.export.cn.plan.relate')" sortable width="180">
                <template slot-scope="params">
                  {{params.row.timeName}}
                </template>
              </u-table-column>
            </u-table>
            <div class="select-ops">
              <Button type="primary" @click="selectLight" style="margin-bottom: 10px">>></Button>
              <Button type="primary" @click="unselectLight" style="margin-bottom: 10px">&lt;&lt;</Button>
            </div> -->
            <u-table ref="table1" style="flex:none;" :data="curchks" row-key="id" use-virtual border stripe :height="485" :big-data-checkbox="true" default-expand-all :empty-text="showLang('com.data.no')">
              <!-- <u-table-column prop="id" type="selection"></u-table-column> -->
              <u-table-column prop="id" type="index"></u-table-column>
              <u-table-column prop="name" :label="showLang('lux.lamp.name')" sortable>
                <template slot-scope="params">
                  {{params.row.lightName}}
                </template>
              </u-table-column>
              <u-table-column prop="location" :label="showLang('com.site.name')" sortable>
                <template slot-scope="params">
                  {{params.row.stationName}}
                </template>
              </u-table-column>
              <u-table-column prop="timeId" :label="showLang('com.export.cn.plan.relate')">
                <template slot-scope="params">
                  {{params.row.timeName}}
                </template>
              </u-table-column>
            </u-table>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <div class="footer">{{showLang('lux.select.ch.num')}}：{{curchks.length}}</div>
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <!-- <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button> -->
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
// import CommonTree from '../../../tree/Index'
export default {
  name: 'ModalLightEdit',
  components:{
    // CommonTree,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      groupName: '',
      form: {
        id: 0,
        lights: [],
      },
      filter: {
        channel: 0,
        timeId: 0,
      },
      channels: [],
      rules: {
        name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
      filterTms: [],
      filterChs: [],
      treeData: [],
      devices: [],
      curchks: [],
      timeTables: [],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // this.initTree();
        this.isAdd = this.item.isAdd;
        this.curchks = [];
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.lights = this.item.data.lights;
        }else{
          this.form.id = 0;
          this.form.lights = [];
        }
        // this.getUseablePlan();
        this.initSelected();
        // this.initList();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
    selectedNode(){
      // this.initList();
    },
    'filter.timeId'(){
      // this.initList();
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['cmdResultRefresh', 'cmds']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'groups']),
  },
  mounted: function(){
    // this.getProjects();
    this.rules.name.message=this.showLang('lux.name.null.leng20')
  },
  methods: {
    getUseablePlan: function(){
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    selectLight: function(){
      let chks = this.$refs.table.getCheckboxRecords();
      for(let chk of chks){
        if(this.curchks.filter(p => p.paramId == chk.paramId).length == 0){
          this.curchks.push(chk);
        }
      }
      // console.log('sel l', chks);
      // this.curchks.push(...chks);
      // for(let chk of chks){
      //   for(let i = 0; i < this.devices.length; i++){
      //     if(this.devices[i].id == chk.id){
      //       this.devices.splice(i, 1);
      //       i--;
      //       break;
      //     }
      //   }
      // }
    },
    unselectLight: function(){
      let chks = this.$refs.table1.getCheckboxRecords();
      // console.log('unsel l', chks);
      for(let chk of chks){
        for(let i = 0; i < this.curchks.length; i++){
          if(this.curchks[i].paramId == chk.paramId){
            this.curchks.splice(i, 1);
            i--;
            break;
          }
        }
      }
      // this.devices.push(...chks);
    },
    initList: function(){
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryAllLights`, {groupId, stationId, timeId: this.filter.timeId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'devices', res.data);
        }
      });
    },
    initSelected: function(){
      if(this.form.id <= 0){
        this.$set(this, 'curchks', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryLights`, {id: this.form.id}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'curchks', res.data);
        }
      });
    },
    ok: async function () {
      if(this.curchks.length == 0){
        this.$Message.warning(this.showLang('lux.selected.Control.lamp'));
        return;
      }
      this.form.lights = this.curchks.map(p => p.paramId);
      this.$axios.post(`//${this.domains.trans}/station/lux/SetLights`, this.form).then(res => {
        if(res.code == 0){
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved');
        }
      });
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.select-ops{
  margin: 0 10px;
  /* border: solid 1px rgba(202, 200, 200, 0.473); */
  width: 50px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.select-list{
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 200px;
  flex: auto;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.chks-list{
  display: flex;
}
.select-header{
  height: 30px;
  flex: none;
  line-height: 30px;
  border-bottom: solid 1px rgba(202, 200, 200, 0.473);
}
.select-body{
  height: 200px;
  flex: auto;
}
.form-area{
  /* border: solid 1px red; */
  height: 537px;
  display: flex;
  flex-direction: column;
}
.edit-area{
  /* border: solid 1px rgba(202, 200, 200, 0.473); */
  height: 32px;
  flex: none;
  display: flex;
  margin-bottom: 5px;
}
.channel-area{
  /* border: solid 1px rgba(202, 200, 200, 0.473); */
  height: 290px;
  flex: auto;
  display: flex;
}
.channel-tree{
  /* border: solid 1px red; */
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 250px;
  flex: none;
  margin-right: 10px;
  overflow: auto;
}
.channel-chks{
  /* border: solid 1px red; */
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 500px;
  flex: auto;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.chks-header{
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px red; */
  align-items: center;
}
.footer{
  /* border: solid 1px red; */
  display: inline-flex;
  float: left;
  line-height: 40px;
  margin-left: 20px;
  width: 500px;
  /* height: 100%; */
}
.show-area{
  /* border:solid 1px red; */
  width: 300px;
  flex:auto;
  text-align:right;
  display:flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}
</style>
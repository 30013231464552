<template>
  <div :ref="`t${node.type}i${node.id}`" class="tree-node">
    <div :class="['node-item', (selectedNode.id == node.id && selectedNode.type == node.type) ? 'node-selected':'']" @click="nodeClick">
      <div :class="['node-item-flag', (selectedNode.id == node.id && selectedNode.type == node.type) ? 'node-selected-flag':'']"></div>
      <div :style="{width: `${(this.curPath.length - 1) * 10}px`, height: '44px', flex: 'none'}"></div>
      <img class="node-img" :src="getImgType()">
      <span :class="nodeTextClass" :title="node.name">{{node.name}}</span>
      <img v-if="node.children && node.children.length > 0" class="expand-img" :src="node.expand ? img.light.road.tree.up : img.light.road.tree.down">
    </div>
    <div v-if="node.expand" class="tree-nodes">
      <template v-if="node.children">
        <MyTreeNode v-for="(n, idx) in node.children" :path="curPath" :level="idx" :key="`${n.type}_${n.id}`" :node="n" />
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MyTreeNode from './MyTreeNode'
export default {
  name: 'MyTreeNode',
  components: {
    MyTreeNode,
  },
  props: {
    node: {
      type: Object,
      default() { return {}; }
    },
    path: {
      type: Array,
      default() { return []; }
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      curPath: [...this.path, this.level],
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'filterTreeNode', 'selectedDevices','lightPowerError']),
    ...mapState('common', ['productCodes', 'deviceTypes']),
    nodeTextClass: function () {
      if (this.node.type == 'root') {
        return 'node-text-root';
      } else if (this.node.type == 'group') {
        return 'node-text-group';
      } else {
        return 'node-text-dev';
      }
    },
  },
  watch: {
    filterTreeNode() {
      if (this.filterTreeNode.type == this.node.type && this.filterTreeNode.id == this.node.id) {
        this.$refs[`t${this.node.type}i${this.node.id}`].scrollIntoView({behavior:'auto',block:'center'});
      }
    }
  },
  mounted: function () {
  },
  destroyed: function(){
  },
  methods: {
    getImgType: function () {
      switch (this.node.type) {
        case 'root': return this.img.light.road.tree.root;
        case 'group': return this.img.light.road.tree.group;
        default: {
          if(this.node.status == 3){ //停用
            return this.img.gis.tree.stop;
          }else if(this.node.status == 2){ //维修
            return this.img.gis.tree.repair;
          }else if(!this.node.online){ //离线
            return this.img.gis.tree.offline;
          }else if(this.node.running){ //亮灯
            if(this.node.alarm || this.lightPowerError.filter(p => p.stationId == this.node.id).length==1){ //报警
              return this.img.gis.tree.lightingPolice;
            }else{ //无报警
              return this.img.gis.tree.lighting;
            }
          }else{ //灭灯
            if(this.node.alarm || this.lightPowerError.filter(p => p.stationId == this.node.id).length==1){ //报警
              return this.img.gis.tree.outPolice;
            }else{ //无报警
              return this.img.gis.tree.out;
            }
          }
        }
      }
    },
    nodeClick: function () {
      if (this.selectedNode.id == this.node.id && this.selectedNode.type == this.node.type) {
        this.node.expand = !this.node.expand;
      } else {
        this.$store.commit('group/selectNode', this.node);
        if (!this.node.expand) this.node.expand = true;
      }
    },
  }
}
</script>
<style scoped>
.tree-node {
  width: 100%;
}
.node-item {
  height: 44px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  /* border: solid 1px red; */
}
.node-item-flag {
  width: 6px;
  background-color: transparent;
}
.node-text-group {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
  margin-left: 5px;
  height: 44px;
  line-height: 44px;
  user-select: none;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.node-text-root {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
  margin-left: 5px;
  height: 44px;
  line-height: 44px;
  user-select: none;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.node-text-dev {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  margin-left: 5px;
  height: 44px;
  line-height: 44px;
  user-select: none;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.node-img {
  /* width: 24px;
    height: 24px; */
  margin: 5px;
  flex: none;
}
.expand-img {
  width: 12px;
  height: 8px;
  margin: 15px;
  flex: none;
}
.node-selected {
  background: #ddeffe;
}
.node-selected-flag {
  background-color: #0cd3fc;
  width: 6px;
  height: 44px;
  background: linear-gradient(0deg, #3176fb, #5eb4ff);
  border-radius: 0px 4px 4px 0px;
}
</style>
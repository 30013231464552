<template>
  <Modal v-model="showModal" :mask-closable="false" width="400px">
    <div slot="header">{{showLang('lux.edit.control.group')}}</div>
    <div class="form-area">
      <div class="edit-area">
        <Input type="text" v-model="form.name" :placeholder="showLang('save.name')" style="width: 350px;flex:none;margin-bottom: 10px"><span slot="prepend">{{showLang('com.group.name')}}</span></Input>
        <!-- <Input type="text" v-model="form.remark" :placeholder="showLang('lux.Group.illustrate')" style="width: 350px;flex:auto;"><span slot="prepend">{{showLang('lux.Group.illustrate')}}</span></Input> -->
      </div>
    </div>
    <div slot="footer">
      <!-- <div class="footer">当前已选通道数量：{{curchks.length}}</div> -->
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalGroupEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      groupName: '',
      form: {
        id: 0,
        name: '',
        // channels: [],
        // lights: [],
        // remark: '',
      },
      rules: {
        name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
      showSelected: false,
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // this.initTree();
        this.isAdd = this.item.isAdd;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          // this.form.remark = this.item.data.remark;
        }else{
          this.form.id = 0;
          this.form.name = '';
          // this.form.remark = '';
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['cmdResultRefresh', 'cmds']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'groups']),
  },
  mounted: function(){
    // this.getProjects();
    this.rules.name.message=this.showLang('lux.name.null.leng20')
  },
  methods: {
    ok: function () {
      if(this.form.name.length == 0){
        this.$Message.warning(this.showLang('lux.Enter.group.name'));
        return;
      }
      // if(this.form.remark.length > 200){
      //   this.$Message.warning(this.showLang('lux.group.leng200'));
      //   return;
      // }
      this.$axios.post(`//${this.domains.trans}/station/lux/SaveOutputGroup`, this.form).then(res => {
        if(res.code == 0){
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-area{
  /* border: solid 1px red; */
  height: 137px;
  display: flex;
  flex-direction: column;
}
.edit-area{
  /* border: solid 1px rgba(202, 200, 200, 0.473); */
  height: 32px;
  flex: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.channel-area{
  /* border: solid 1px rgba(202, 200, 200, 0.473); */
  height: 290px;
  flex: auto;
  display: flex;
}
.channel-tree{
  /* border: solid 1px red; */
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 250px;
  flex: none;
  margin-right: 10px;
  overflow: auto;
}
.channel-chks{
  /* border: solid 1px red; */
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 500px;
  flex: auto;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.chks-header{
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px red; */
  align-items: center;
}
.footer{
  /* border: solid 1px red; */
  display: inline-flex;
  float: left;
  line-height: 40px;
  margin-left: 20px;
  width: 500px;
  /* height: 100%; */
}
.show-area{
  /* border:solid 1px red; */
  width: 300px;
  flex:auto;
  text-align:right;
  display:flex;
  align-items: center;
  justify-content: flex-end;
  /* padding-right: 10px; */
}
</style>
<template>
  <div class="config-station-container">
    <div class="station-data-area" ref="table">
      <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="list" :height="tabHeight" :row-config="{isHover: true}">
        <vxe-column type="seq" width="60" ></vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" width="200" header-align="center"></vxe-column>
        <vxe-column field="code" title="通信ID" width="150" header-align="center"></vxe-column>
        <vxe-column field="isOuter" title="环境类型" width="80" header-align="center">
          <template #default="{ row }">
            {{row.isOuter ? '外部' : '内部'}}
          </template>
        </vxe-column>
        <vxe-column field="ruleName" title="应用规则" width="200" header-align="center"></vxe-column>
        <vxe-column field="transTypeName" title="通信设备类型" width="200" header-align="center"></vxe-column>
        <vxe-column field="smallTypeName" title="采集设备类型" width="200" header-align="center"></vxe-column>
        <vxe-column field="address" title="采集设备地址" width="120" header-align="center"></vxe-column>
        <vxe-column field="transChannel" title="上行通道" width="120" header-align="center"></vxe-column>
        <vxe-column field="rate" title="倍率" width="120" header-align="center"></vxe-column>
        <vxe-column field="simCard" title="SIM卡号" width="200" header-align="center"></vxe-column>
        <vxe-column field="buildDate" title="安装日期" width="150" header-align="center">
          <template #default="{ row }">
            {{new Date(row.buildDate).format('yyyy-MM-dd')}}
          </template>
        </vxe-column>
        <vxe-column field="location" title="安装位置" width="200" header-align="center"></vxe-column>
        <vxe-column width="220" title="操作" fixed="right">
          <template #header>
            <Button v-if='funCodes("lsa")' size="small" type="primary" style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</Button>
            <Button v-if='funCodes("lsv")' size="small" type="primary" style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</Button>
          </template>
          <template #default="params">
            <Button v-if='funCodes("lse")' size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">{{showLang('com.op.edit')}}</Button>
            <Button v-if='funCodes("lsd")' size="small" type="error" style="margin-right: 5px" @click="handleDelete(params)">{{showLang('com.op.del')}}</Button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <ModalStationEdit v-model="showEditModal" :item="editItem" :formArgs="formArgs" @saved="getList" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalStationEdit from './ModalStationEdit'
export default {
  name: 'ConfigStationIndex',
  components: {
    ModalStationEdit,
  },
  data() {
    return {
      showEditModal: false,
      tabHeight: 300,
      editItem: { isAdd: true, index: -1, data: {} },
      list: [],
      formArgs: {
        outputGroups: [],
        lightGroups: [],
        rules: [],
        smallTypes: [],
        transTypes: [],
      },
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize() {
      this.tabHeight = this.$refs.table.clientHeight
      // console.log('windowOnResize', this.windowOnResize, this.$refs.table.clientHeight)
    },
  },
  mounted: function () {
    setTimeout(() => {
      this.tabHeight = this.$refs.table.clientHeight;
    }, 200);
    this.getRules();
    this.getEnvDeviceTypes();
    this.getTransDeviceTypes();
    this.getList();
  },
  methods: {
    getGroupName: function (id) {
      let grps = this.groups.filter(p => p.id == id);
      if (grps.length == 0) return '';
      return grps[0].name;
    },
    getEnvDeviceTypes: function () {
      this.$axios.post(`device/lux/GetEnvDeviceTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.formArgs, 'smallTypes', res.data);
        }
      });
    },
    getTransDeviceTypes: function () {
      this.$axios.post(`device/lux/GetTransDeviceTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.formArgs, 'transTypes', res.data);
        }
      });
    },
    getList: function () {
      this.$axios.post(`device/lux/QueryDevice`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
        }
      });
    },
    getRules: function () {
      this.$axios.post(`device/lux/QueryRule`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.formArgs, 'rules', res.data);
        }
      });
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.showEditModal = true;
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.row.name),
        onOk: async () => {
          this.$axios.post(`device/lux/DeleteDevice`, {id: params.row.id}).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.config-station-container {
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
}
.station-search-area {
  height: 42px;
  flex: none;
  border: solid 1px #dcdee2;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-top: 3px;
}
.station-data-area {
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>
<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">编辑联动规则</div>
    <Form ref="form" :model="form" :label-width="130">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-right: 15px">
        <Input type="text" v-model="form.name" :placeholder="showLang('com.tab.title')" style="width: 200px; margin-right: 20px;"></Input>
      </FormItem>
      <FormItem prop="stationId" label="关联设备" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.luxId" style="width: 200px; margin-right: 25px;">
          <template v-for="(item, idx) in args.luxes">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalLampEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    args: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      form: {
        id: 0,
        luxId: '', //所属灯控分组ID
        name: '',
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          for(let key in this.form){
            // console.log('edit form', key, this.item.data[key])
            this.form[key] = this.item.data[key];
          }
        } else {
          this.form.id = '';
          this.form.luxId = '';
          this.form.name = '';
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    // 'form.stationId'(){
    //   this.form.paramId = 0;
    // },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function () {
  },
  methods: {
    ok: async function () {
      if (this.form.name.trim().length == 0) {
        this.$Message.warning(this.showLang('save.name'));
        return;
      }
      if(!this.form.luxId)this.form.luxId = '';
      if (this.form.luxId.length == 0) {
        this.$Message.warning("请选择关联设备");
        return;
      }
      this.$axios.post(`device/lux/SaveLightUnionConfig`, this.form).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
        }
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.edit-form-item{
  border: solid 1px red;
  margin-top: 5px;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>
<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">{{command.name}}</div>
    <Form :model="form" :label-width="120">
      <FormItem label="确认密码" v-if="command.needPswd">
        <div v-if="command.needPswd">
          <Input v-model="form.pswd" type="password" style="width: 120px;height:32px;"></Input>
          <span style="color:red;margin-left:20px">*** 此操作需要确认用户密码</span>
        </div>
        <div v-else>密码还在有效期中</div>
      </FormItem>

      <FormItem label="服务器IP地址" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.host" type="number" style="width: 220px;height:32px;"></Input>
      </FormItem>
      <FormItem label="服务器端口" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.port" type="number" style="width: 220px;height:32px;"></Input>
      </FormItem>
      <FormItem label="APN名称" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.apn" type="number" style="width: 220px;height:32px;"></Input>
      </FormItem>
      <FormItem label="APN用户" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.user" type="number" style="width: 220px;height:32px;"></Input>
      </FormItem>
      <FormItem label="APN密码" v-if="['setNetwork'].indexOf(command.code) != -1" class="form-item">
        <Input v-model="form.pswd" type="text" style="width: 220px; height: 32px"></Input>
      </FormItem>

      <FormItem label="1号设备地址" v-if="['setLuxArgs'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.addr1" style="width: 220px;">
          <template v-for="el in addList">
            <Option  :key="el.id" :value="el.id">{{el.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="2号设备地址" v-if="['setLuxArgs'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.addr2"  style="width: 220px;">
          <template v-for="el in addList">
            <Option :key="el.id" :value="el.id">{{el.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="3号设备地址" v-if="['setLuxArgs'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.addr3"  style="width: 220px;">
          <template v-for="el in addList">
            <Option  :key="el.id" :value="el.id">{{el.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="上报间隔" v-if="['setLuxArgs'].indexOf(command.code) != -1" class="form-item">
        <Input v-model="form.interval" type="text" style="width: 220px; height: 32px">
        <span slot="append">(5-255)秒钟</span>
        </Input>
      </FormItem>

    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" @click="ok">发送</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalArgEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    command: {
      type: Object,
      default() { return {}; }
    },
    areas: {
      type: Array,
      default() { return []; }
    }
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      addList:[
        {id:0,name:'不启用'},
        {id:1,name:'1'},
        {id:2,name:'2'},
        {id:3,name:'3'},
      ],
      events: {},
      timeTables: [],
      form: {
        content: null,
        host: "", //服务器IP地址
        port: 59996, //服务器端口
        apn: "", //APN名称
        user: "", //APN用户
        pswd: "", //APN密码
        addr1: 1, //1号设备地址。（默认1）
        addr2: 2, //2号设备地址。（默认2）
        addr3: 3, //3号设备地址。（默认3）
        interval: 10, //上报间隔，5-255，单位：秒钟。（默认10）
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['needCheckPswd']),
    
    ...mapState('group', ['groups', 'selectedNode', 'selectedGroups', 'rootTitle']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'weeks1', 'ampm', 'coms8088names', 'com8088types', 'com8088buads', 'com8088datas', 'com8088paritys', 'com8088stops']),
 
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      this.form.port=localStorage.getItem('portRecord')==null?59996:localStorage.getItem('portRecord');//默认端口
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    'form.port'(){
      localStorage.setItem('portRecord', this.form.port);
    },
  },
  methods: {
    createCmd: function () {
      let args;
      if (this.command.code == 'setNetwork') {
        args = { host: this.form.host, port: this.form.port, apn: this.form.apn, user: this.form.user, pswd: this.form.pswd };
      } else if (this.command.code == 'setLuxArgs') {
        args = { addr1: this.form.addr1, addr2: this.form.addr2, addr3: this.form.addr3, interval: this.form.interval };
      } else {
        args = {};
      }
      this.$emit('saved', { code: this.command.code, name: this.command.name, args });
      this.showModal = false;
      this.form.pswd = '';
    },
    ok: function () {
      if (this.command.code == 'setLuxArgs') {
        if (this.form.addr1 == 0 && this.form.addr2 == 0 && this.form.addr3 == 0) {
          this.$Message.warning('设备地址至少启用一个,选择一个地址');
          return;
        }
      }

      if (this.needCheck) {
        if (this.form.pswd == '') {
          this.$Message.warning('请输入密码');
          return;
        }
        this.$store.dispatch('auth/checkPswd', { pswd: this.form.pswd }).then(res => {
          if (res.code == 0) {
            this.createCmd();
          }
        });
      } else {
        this.createCmd();
      }
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.set-table tr td {
  border: solid 1px gray;
  text-align: center;
  white-space: nowrap;
}
.set-table {
  width: 100%;
}
.time-span {
  display: flex;
}
.min-width {
  width: 70px;
  flex: none;
  margin: 0;
}
.ch-width {
  width: 120px;
  flex: none;
  margin: 0 10px;
  display: flex;
}
.ch-width span {
  width: 25px;
  flex: none;
}
.ch-width div {
  width: 40px;
  flex: auto;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>
<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">编辑联动规则</div>
    <Form ref="form" :model="form" :label-width="130">
      <FormItem prop="start" label="光控起始值" style="margin-right: 15px">
        <Input type="number" v-model="form.start" placeholder="光控起始值" style="width: 200px; margin-right: 20px;"></Input>
      </FormItem>
      <FormItem prop="end" label="光控截止值" style="margin-right: 15px">
        <Input type="number" v-model="form.end" placeholder="光控起始值" style="width: 200px; margin-right: 20px;"></Input>
      </FormItem>
      <FormItem prop="open" label="控制方式" style="margin-right: 15px">
        <i-switch v-model="form.open" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
          <span slot="open">通电</span>
          <span slot="close">断电</span>
        </i-switch>
      </FormItem>
      <FormItem v-if="!form.handAll" prop="groupIds" label="控制分组" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.groupIds" multiple style="width: 200px; margin-right: 25px;">
          <template v-for="(item, idx) in groups">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="expire" label="延时时间" style="margin-right: 15px">
        <Input type="number" v-model="form.expire" placeholder="延时时间" style="width: 200px; margin-right: 20px;"></Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalLampEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    args: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      form: {
        id: '',
        infoId: '',
        start: 0,
        end: 0,
        open: false,
        expire: 1000,
        groupIds: [],
      },
      groups: [],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.getGroups();
        console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        this.form.infoId = this.item.data.infoId;
        if (!this.isAdd) {
          for(let key in this.form){
            // console.log('edit form', key, this.item.data[key])
            this.form[key] = this.item.data[key];
          }
        } else {
          this.form.id = '';
          this.form.start = 0;
          this.form.end = 100;
          this.form.open = false;
          this.form.expire = 1000;
          this.form.groupIds = [];
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    // 'form.stationId'(){
    //   this.form.paramId = 0;
    // },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function () {
  },
  methods: {
    getGroups: function () {
      this.$axios.post(`device/lux/QueryOutputGroup`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'groups', res.data);
        }
      });
    },
    ok: async function () {
      this.$axios.post(`device/lux/SaveOutputRuleConfig`, this.form).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
        }
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.edit-form-item{
  border: solid 1px red;
  margin-top: 5px;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>
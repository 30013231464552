<template>
  <div class="box-container" ref="boxTreeHeight">
    <div class="box-slider">
      <MyTree class="box-tree" ref="tree" :data="treeData" />
      <List v-if="filter.length > 0" border size="small" class="tree-search-result">
        <template v-if="filterList.length == 0">
          {{isShowTip?showLang('com.data.no'):showLang('com.data.search')}}
        </template>
        <template v-else v-for="(item, idx) in filterList">
          <ListItem v-if="!item.isStation" class="node-selected" @click.native="selectIt(item)" :key="idx">{{showLang('com.group')}}：{{item.name}}</ListItem>
          <ListItem v-else class="node-selected" @click.native="selectIt(item)" :key="idx">{{showLang('com.site.device')}}：{{item.name}}</ListItem>
        </template>
      </List>
    </div>
    <div class="tree-search">
      <!-- <img :src="img.treeSearchImg"/>
      <input type="text" v-model="filter" placeholder="输入编码或名称查询设备22" /> -->
      <Input search clearable v-model="filter" autocomplete="off" class="text-bg" :placeholder="showLang('save.code.name.dev')" />
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import MyTree from './MyTree'
export default {
  name: 'CommonTreeIndex',
  components: {
    MyTree,
  },
  props: {
    args: {
      type: Object,
      default() { return {} }
    }
  },
  data() {
    return {
      showEditGroup: false,
      showChangeGroup: false,
      isShowTip: false,
      editItem: {},
      filter: '',
      result: [],
      filterList: [],
      deviceAll: [],//设备
      timeEnter: null,
    }
  },
  watch: {
    filter() {
      this.keyEnter();
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['treeData', 'devices', 'selectedNode', 'showDeviceTypes', 'selectedDevices']),
    ...mapState('common', ['productCodes', 'deviceTypes']),
    selectedPowers: function () {
      return this.selectedDevices;
    },
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    keyEnter() {
      let this_ = this;
      if (this_.filter == []) {
        this_.$set(this_, 'filterList', []);
        return false;
      }
      if (this_.timeEnter) {
        clearTimeout(this_.timeEnter)
      }
      this_.isShowTip=false;
      this_.timeEnter = setTimeout(function () {
        this_.timeEnter = null;
        if (this_.filter == []) {
          return false;
        }
        this_.$axios.post(`//${this_.domains.trans}/station/config/FilterTree`, { 'filter': this_.filter }).then(res => {
          this_.$set(this_, 'filterList', res.data);
          this_.isShowTip=true;
        });
      }, 1500)
    },
    selectIt: function (nodeData) {
      this.$store.commit('group/selectNodeById', { nodeId: nodeData.id, type: nodeData.isStation ? 'Station' : 'group' });
    },
  }
}
</script>
<style scoped>
.box-container {
  background: #ffffff;
  /* display: flex; */
  width: 100%;
  height: 100%;
  align-items: stretch;
  position: relative;
  overflow-y: auto;
}
.text-bg {
  background-color: #eef7ff !important;
}
.text-bg input {
  background-color: #eef7ff !important;
}
.box-slider {
  flex: none;
  width: 100%;
  /* background-color: #041B32; */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}
.box-tree {
  flex: auto;
}
.tree-search {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 34px;
  width: 270px;
  /* background-color: #132D48; */
  background: #eef7ff;
  border-radius: 6px;
  /* border-radius: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid 1px red; */
}
.tree-search img {
  /* outline-style: none; */
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.tree-search input {
  outline-style: none;
  width: 210px;
  height: 15px;
  /* font-size: 14px;
  background-color: #132D48;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4D6781; */
  background-color: transparent;
  border: 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #889fb4;
}
.tree-search-result {
  position: fixed;
  bottom: 50px;
  left: 10px;
  right: 10px;
  width: 270px;
  background: white;
  max-height: 300px;
  overflow-y: auto;
}
.node-selected {
  cursor: pointer;
}
</style>

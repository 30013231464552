<template>
  <div class="lux-dashboard">
    <div class="lux-list-tree">
      <template v-for="(item, idx) in list">
        <a href="javascript:void(0);" :key="idx" :class="['module-nva-but', listIndex == idx ? 'active' : '']" @click="listBut(idx)">
          <img :src="isImg(item)" />
          <span class="lux-list-name" :title="item.name">[{{ item.isOuter ? '外部' : '内部' }}]{{item.name}}</span>
          <span :key="idx+'s'" class="lux-list-num">{{item.luxValue || '-'}}</span>
        </a>
      </template>
    </div>
    <div class="lux-right-box">
      <div class="lux-right-bottom">
        <div class="lux-bottom-lux">
          <div class="bottom-lux-top">
            <div class="bottom-lux-name"> <img :src="isImg(currentLux)" /> {{currentLux ? currentLux.name : '-'}}</div>
          </div>
          <div class="bottom-lux-icon">
            <img :src="img.lux.nav.luxMix0" alt="">
            <div>
              <div class="bottom-icon-name">{{showLang('cmd.lux')}}：</div>
              <div class="bottom-icon-value">{{ currentLux ? currentLux.luxValue.toFixed(0) : '-'}} lux</div>
            </div>
          </div>
          <div class="bottom-icon-box">
            <div class="bottom-val-box1">
              <img :src="img.lux.nav.temperature" alt="">
              <div>
                <div class="bottom-val-name">{{showLang('com.lighting.T')}}</div>
                <div class="bottom-val-value">{{ currentLux ? currentLux.temperature.toFixed(0) : '-'}} ℃</div>
              </div>
            </div>

          </div>
          <div class="bottom-icon-box">
            <div class="bottom-val-box2">
              <img :src="img.lux.nav.humidity" alt="">
              <div>
                <div class="bottom-val-name">{{showLang('lux.humidity')}}</div>
                <div class="bottom-val-value">{{ currentLux ? currentLux.humidity.toFixed(0) : '-'}} %</div>
              </div>
            </div>
          </div>
          <div class="bottom-icon-box">
            <div class="bottom-val-box1">
              <img :src="img.lux.nav.time" alt="">
              <div>
                <div class="bottom-val-name">{{showLang('lux.Refresh')}}</div>
                <div class="bottom-val-value">{{currentLux && currentLux.fireTime ? new Date(currentLux.fireTime).format('yyyy-MM-dd HH:mm:ss') : '-'}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lux-bottom-chart">
          <p class="lux-chart-name">{{showLang('lux.trend')}}</p>
          <div class="lux-chart-box">
            <div id="chartsBox" class="chartsBox" :key="listIndex"></div>
          </div>
        </div>
      </div>
      <div class="cmd-list">
        <template v-for="el,index in cmdData">
          <Button type="primary" size="default" style="margin: 15px 15px 0 0 " @click="docmd(el)" :key="index">{{el.name}}</Button>
        </template>
      </div>
    </div>
    <!-- <ModalControlGroup v-model="showControlModal" :item="selectedItem" /> -->
    <ModalArgEdit v-model="showArgModal" :command="editItem" @saved="argsSaved" />
  </div>
</template>
<script>
import * as echarts from 'echarts';
import { mapState, mapGetters } from 'vuex'
// import ModalControlGroup from './ModalControlGroup'
import ModalArgEdit from './ModalArgEdit'
export default {
  name: 'LuxDashborad12',
  components: {
    ModalArgEdit,
  },
  data() {
    return {
      list: [],
      listIndex: 0,
      ticker: null,
      timeout: null,
      groups: [],
      showGroups: [],
      selectedItem: {},
      showControlModal: false,
      dataAxis: [],
      dataSeries1: [],
      dataSeries2: [],
      dataSeries3: [],
      nowOptions: {
        visualMap: [
          {
            show: false,
            type: "continuous",
            seriesIndex: 0,
            min: 0,
            max: 400,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false,
          },
        },
        grid: {
          top: "15%",
          bottom: "10%",
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          triggerEvent: true,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            type: "line",
            showSymbol: false,
            hoverAnimation: false,
            name: '光照度',
            data: [],
          },
          {
            type: "line",
            showSymbol: false,
            hoverAnimation: false,
            name: '温度',
            data: [],
          },
          {
            type: "line",
            showSymbol: false,
            hoverAnimation: false,
            name: '湿度',
            data: [],
          },
        ],
      },
      myChart: null,
      timer: 0,
      data: [],
      cmdData: [],
      showArgModal: false,
      editItem: {},
      luxCommand: [],
    }
  },
  computed: {
    ...mapState('auth', ['agentChangeValue']),
    ...mapState('group', ['selectedNode']),
    ...mapState('common', ['powerBoxTypes', 'productCodes']),
    ...mapState('cmd', ['luxDataChanged', 'luxContent']),
    ...mapGetters('auth', ['showLang', 'funCodes']),
    online: function () {
      return function (item) {
        if (item.ts <= 0) return '离线';
        if ((new Date().getTime() - item.ts) > 120 * 1000) return '离线';
        return '在线'
      }
    },
    currentLux: function () {
      if (this.list.length == 0) return null;
      if (this.listIndex >= this.list.length) return null;
      if (this.listIndex < 0) return null;
      return this.list[this.listIndex];
    },
    curentGroupName: function () {
      if (!this.currentLux) return '-';
      if (!this.currentLux.outputGroups) return '-';
      if (this.currentLux.outputGroups.length == 0) return '-';
      let names = [];
      this.currentLux.outputGroups.map(item => {
        names.push(item.name);
      })
      return names.join(',');
    },
  },
  watch: {
  },
  mounted: function () {
    this.getList();
    this.getCmd();
    this.ticker = setInterval(this.checkOnline, 1000);
    this.nowOptions.series[0].name = this.showLang('cmd.lux')
    this.nowOptions.series[1].name = this.showLang('com.lighting.T')
    this.nowOptions.series[2].name = this.showLang('lux.humidity')
    window.eventBus.$on('luxRealDataComing', params => {
      this.setLuxRealData(params);
      this.setListData(params);
    })
    window.eventBus.$on('paramCommandComing', params => {
      this.modalCmd(params)
    })
  },
  destroyed: function () {
    window.eventBus.$off('luxRealDataComing');
    window.eventBus.$off('paramCommandComing');
    if (this.ticker) {
      clearInterval(this.ticker);
      this.ticker = null;
    }
  },
  methods: {
    modalCmd(params) {
      if (this.luxCommand[0] != undefined && this.luxCommand[0].message != undefined && params.commandId == this.luxCommand[0].message) {
        this.$Modal.success({
          title: "指令",
          content: this.modalCmdText(params)
        });
      }
    },
    modalCmdText(params) {
      let content = '';
      content = params.message;
      if (params.status == 9) {
        switch (params.cmdCode) {
          case "getclock":
            content = "设备当前时间" + params.content.nowTime
            break;
          case "setclock":
            content = "指令已完成-校准时钟成功"
            break;
          case "getNetwork":
            content = '服务器IP地址:' + params.content.host + '<br/>服务器端口:' + params.content.port + '<br/>APN名称:' + params.content.apn + '<br/>APN用户：' + params.content.user
            break;
          case "setNetwork":
            content = "指令已完成-设置网络成功"
            break;
          case "getLuxArgs":
            content = `1号设备地址:${params.content.enable1 == 0 ? '不启用' : params.content.enable1}<br/>2号设备地址:${params.content.enable2 == 0 ? '不启用' : params.content.enable2}<br/>3号设备地址:${params.content.enable3 == 0 ? '不启用' : params.content.enable3}<br/>上报数据:${params.content.interval}`
            break;
          case "setLuxArgs":
            content = "指令已完成-设置照度参数成功"
            break;
          case "reset":
            content = "指令已完成-重启成功"
            break;
          case "forceRead":
            content = "指令已完成-召测数据成功"
            break;
        }
        this.history(this.list[this.listIndex]);
      }
      return content
    },
    docmd(params) {
      this.editItem = params;
      if (params.needArgs || params.needPswd) {
        this.showArgModal = true;
      } else {
        this.argsSaved({ code: params.code, args: {}, name: params.name })
      }
    },
    getCmd() {
      this.$axios.post(`device/lux/QueryLuxCmds`, {}).then(res => {
        if (res.code === 0) {
          this.$set(this, 'cmdData', res.data);
        }
      });
    },
    argsSaved: function (params) {
      let data = {
        "code": params.code,
        "list": [],
        "checkUserId": 0,
        "args": params.args
      }
      data.list.push(this.list[this.listIndex].id);
      this.$axios.post(`//${this.domains.trans}/device/lux/SendLuxCommand`, data).then(res => {
        if (res.code == 0) {
          this.$set(this, 'luxCommand', res.data.list);
          if (!res.data.list[0].success) {
            this.$Message.error({
              background: true,
              top: 50,
              content: res.data.list[0].message
            });
          }
        }
      });
    },
    setListData(params) {
      let arr = this.list.filter(el => el.id == params.id)
      if (arr.length > 0) {
        arr[0].humidity = params.hum;
        arr[0].luxValue = params.lux;
        arr[0].temperature = params.temp;
        arr[0].fireTime = params.time;
      }
      this.checkOnline()
    },
    setLuxRealData: function (params) {
      let item = this.list[this.listIndex];
      if (item.id != params.id) return;
      this.$set(item, 'luxValue', params.lux);
      this.$set(item, 'temperature', params.temp);
      this.$set(item, 'humidity', params.hum);
      this.$set(item, 'fireTime', params.time);
      for (var i = 0; i < 1; i++) {
        if (this.dataSeries1.length == 1000) {
          this.dataSeries1.shift();
          this.dataSeries2.shift();
          this.dataSeries3.shift();
        }
        this.dataSeries1.push({ name: params.time, value: [params.time, params.lux.toFixed(0)] })
        this.dataSeries2.push({ name: params.time, value: [params.time, params.hum.toFixed(0)] })
        this.dataSeries3.push({ name: params.time, value: [params.time, params.temp.toFixed(0)] })
      }
      this.updateChart()

    },
    isImg(item) {
      if (!item) return false;
      if (!item.online) {
        return this.img.lux.nav.offline;
      } else {
        return this.img.lux.nav.online;
      }
    },
    listBut(index) {
      this.listIndex = index;
      this.history(this.list[index]);
      // this.showGroups1(this.list[index]);
    },
    showGroups1(item) {
      this.$set(this, 'showGroups', item.outputGroups)
    },
    clickItem: function (item) {
      this.selectedItem = item;
      this.showControlModal = true;
    },
    debounceInitData: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.getList, 500);
    },
    checkOnline: function () {

      let time = new Date().getTime();
      for (let item of this.list) {
        let diff = time - new Date(item.fireTime).getTime();
        let online = diff < 300 * 1000;
        this.$set(item, 'online', online)
      }
    },
    getImgType: function (item) {
      if (!item.online) {
        return this.img.tree.station.offliner;
      } else {
        return this.img.tree.station.onlineOn;
      }
    },
    viewHistory: function (item) {
      if (!item) return;
      this.$emit('doAction', { cmd: 'gotoSearch', stationId: item.id })
    },
    nowChart(el) {
      this.dataSeries1 = [];
      this.dataSeries2 = [];
      this.dataSeries3 = [];
      el.forEach(itme => {
        this.now = itme.fireTime;
        this.dataSeries1.push({ name: '光照度', value: [itme.fireTime, itme.luxValue.toFixed(0)] })
        this.dataSeries2.push({ name: '温度', value: [itme.fireTime, itme.humidity.toFixed(0)] })
        this.dataSeries3.push({ name: '湿度', value: [itme.fireTime, itme.temperature.toFixed(0)] })
      });
      this.myChart = echarts.init(document.getElementById("chartsBox"));
      let arr = []
      this.nowOptions.series[0].data = [];
      this.nowOptions.series[1].data = [];
      this.nowOptions.series[2].data = [];
      let options = Object.assign(this.nowOptions, {});
      for (let index = 0; index < 3; index++) {
        if (index == 0) { arr = this.dataSeries1; }
        if (index == 1) { arr = this.dataSeries2; }
        if (index == 2) { arr = this.dataSeries3; }
        options.series[index].data = arr;
        options.series[index].markPoint = {
          data: [
            [
              {
                symbol: "circle",
                value: arr[el.length - 1] == undefined ? '0' : arr[el.length - 1].value[1],
                xAxis: arr[el.length - 1] == undefined ? '0' : arr[el.length - 1].value[0],
              },
            ],
          ],
        };
      }

      this.myChart.setOption(options);
    },
    //更新图表
    updateChart() {
      let arr = [];
      let options = Object.assign(this.nowOptions, {});
      options.series.forEach((item, index) => {
        if (index == 0) { arr = this.dataSeries1; }
        if (index == 1) { arr = this.dataSeries2; }
        if (index == 2) { arr = this.dataSeries3; }
        item.data = arr;
        item.markPoint = {
          data: [
            [{
              symbol: "circle",
              value: arr[arr.length - 1].value[1],
              xAxis: arr[arr.length - 1].value[0],
            },],

          ],
        };
      });
      this.myChart.setOption(options);
    },

    history(el) {
      let now = new Date();
      now.setHours(now.getHours() + 1);
      let end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setHours(now.getHours() - 3);
      let start = now.format('yyyy-MM-dd HH:mm:ss');
      let ajaxData = {
        "stationId": el.id,
        "start": start,
        "end": end,
        "index": 1,
        "size": 20000
      }
      this.$axios.post(`device/lux/QueryLogs`, ajaxData).then(res => {
        if (res.code === 0) {
          res.data.list.sort((a, b) => {
            if (a.fireTime == b.fireTime) return 0;
            return a.fireTime > b.fireTime ? 1 : -1;
          })
          this.nowChart(res.data.list.slice(-1000))
        }
      });
    },
    getList: function () {
      this.$axios.post(`device/lux/QueryState`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
          if (res.data.length > 0) {
            this.listBut(0);
          }
          this.checkOnline();
        }
      });
    },
  }
}
</script>
<style scoped>
.lux-dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.lux-list-tree {
  width: 400px;
  flex: none;
  height: calc(100% - 10px);
  background: #ffffff;
  border: solid 1px rgba(197, 197, 197, 0.37);
  padding-top: 20px;
  margin-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.lux-right-box {
  border: solid 1px rgba(197, 197, 197, 0.37);
  background: #ffffff;
  width: 500px;
  flex: auto;
  /* height: 100%; */
}
.lux-list-name {
  display: inline-block;
  width: calc(100% - 130px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.lux-list-num {
  float: right;
  margin-right: 20px;
}
.lux-right-top {
  background: #ffffff;
  padding: 20px;
  height: 228px;
}
.lux-right-top p {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.lux-groupList {
  display: flex;
  flex-direction: revert;
  margin-top: 30px;
}
.lux-groupList-box {
  text-align: center;
  margin: 0 80px 0 20px;
}
.lux-groupList .lux-groupList-box div {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-top: 10px;
}
.module-nva-but {
  width: 100%;
  height: 48px;
  display: inline-block;
  line-height: 48px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.module-nva-but.active {
  background: #ddeffe;
}
.module-nva-but.active::after {
  content: "";
  width: calc(100%);
  height: 48px;
  display: block;
  margin: -66px auto 0;
  border-left: 4px solid #3880fc;
  border-radius: 0px 6px 6px 0px;
}
.module-nva-but img {
  display: block;
  float: left;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 11px;
}
.cmd-list {
  height: 60px;
  padding-left: 30px;
}
.lux-right-bottom {
  display: flex;
  justify-content: space-between;
}
.lux-bottom-lux {
  width: 450px;
  background: #ffffff;
  padding: 30px;
}
.lux-bottom-chart {
  width: calc(100% - 450px);
  background: #ffffff;
  padding: 20px;
}
.bottom-lux-top {
  display: flex;
  justify-content: space-between;
}
.bottom-lux-name {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.bottom-lux-name img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.bottom-lux-but a {
  width: 79px;
  height: 32px;
  background: #3880fc;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  text-align: center;
  color: #ffffff;
  line-height: 32px;
}
.bottom-lux-but a img {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}
.bottom-lux-icon {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
}
.bottom-lux-icon img {
  margin-right: 39px;
}
.bottom-icon-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  line-height: 26px;
  margin-top: 20px;
}
.bottom-icon-value {
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  line-height: 26px;
  margin-top: 20px;
}
.bottom-icon-box {
  display: flex;
  justify-content: flex-start;
  margin-top: 35px;
}

.bottom-val-box1 {
  display: flex;
  justify-content: flex-start;
  width: 330px;
}
.bottom-val-box2 {
  display: flex;
  justify-content: flex-start;
}
.bottom-val-name {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-left: 35px;
}
.bottom-val-value {
  width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
  line-height: 26px;
  margin-left: 35px;
}
.lux-chart-name {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.lux-chart-box {
  width: 100%;
  height: 460px;
  margin-bottom: 20px;
}
.chartsBox {
  width: 100%;
  height: 500px;
}
</style>